import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Helmet } from "react-helmet";
const TITLE = "EPUB Accessibility Tool";

ReactDOM.render(
  <>
    <Helmet>
      <title>{TITLE}</title>
    </Helmet>
    <App />
  </>,
  document.getElementById("root")
);
