import React from "react";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// const URL = `http://104.219.251.137:3001`;
const URL = `https://api.accessibility.patelinfo.com/`;
// const URL = `http://localhost:3001`;
export default class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: props.fullname,
      selectedFile: props.selectedFile,
      error_log: props.error_log,
      isActive: false,
      modalShow: false,
      modalData: props.modalData,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalClose() {
    this.setState({ modalShow: false });
  }
  handleChange(event) {
    this.setState({ fullname: event.target.value });
  }
  handleFileChange(event) {
    this.setState({ selectedFile: event.target.files[0] });
  }
  handleFileUpload(event) {
    this.setState({ error_log: "File Uploading", isActive: true });
    const formData = new FormData();
    formData.append(
      "data",
      this.state.selectedFile,
      this.state.selectedFile.name,
      this.state.fullname
    );
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(URL + "/single", formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        if (res.status === 200) {
          this.setState({
            error_log: "File Convert Complete",
            isActive: false,
          });
          var da = URL + "/" + res.data;
          window.open(da.replace("./", "/"), "_blank").focus();
        } else if (res.status === 500) {
          this.setState({
            error_log: "File Convert Error Try Again",
            isActive: false,
          });
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        this.setState({
          error_log: "Error",
          isActive: false,
        });
      });

    event.preventDefault();
  }
  render() {
    return (
      <LoadingOverlay active={this.state.isActive} spinner text="Loading ...">
        <div
          id="Main-div"
          className="flex items-center h-screen w-full bg-teal-lighter"
        >
          <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
            <h1 className="block w-full text-2xl text-indigo-600 text-bold text-center mb-6">
              EPUB Accessibility Tool
            </h1>
            <form
              onSubmit={this.handleFileUpload}
              className="mb-4 md:flex md:flex-wrap md:justify-between"
            >
              <div className="flex flex-col mb-2 md:w-full">
                <label className="text text-gray-800" htmlFor="fullname">
                  Full Name
                </label>
                <input
                  className="border py-2 px-3 text-gray-800"
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Enter Your Full Name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="flex flex-col mb-6 md:w-full">
                <label className="text text-gray-800" htmlFor="fullname">
                  Selected File Name
                </label>
                <input
                  className="border py-2 px-3 text-gray-800"
                  type="text"
                  name="file"
                  id="file"
                  placeholder="No File Selected"
                  value={
                    this.state.selectedFile
                      ? this.state.selectedFile.name
                      : "No File Selected"
                  }
                  disabled
                />
              </div>
              <label className="w-1/2 h-full flex flex-col items-center px-2 py-5 bg-white rounded shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-indigo-600 hover:text-white text-indigo-600 ease-linear transition-all duration-150">
                <i className="fas fa-cloud-upload-alt fa-3x"></i>
                <span className="mt-2 text-base leading-normal">
                  Select a file
                </span>
                <input
                  type="file"
                  className="hidden"
                  accept=".zip,.epub"
                  onChange={this.handleFileChange}
                />
              </label>
              <div className="flex flex-col mb-4 md:w-1/2">
                <button
                  className="mx-2 my-1 mt-0 font-bold bg-white hover:bg-indigo-600 text-indigo-600 shadow-md hover:text-white uppercase text-lg p-4 rounded"
                  type="submit"
                  onClick={this.handleFileUpload}
                >
                  Upload
                </button>
                <button
                  className="hidden mx-2 my-1 mb-0 font-bold bg-white hover:bg-green-600 text-green-600 shadow-md hover:text-white uppercase text-lg p-4 rounded"
                  type="button"
                  onClick={this.handleFileUpload}
                >
                  Download
                </button>
              </div>
            </form>
            <p
              id="p-log"
              className="block w-full text-center text-4xl no-underline text-red-500 hover:text-grey-darker"
            >
              {this.state.error_log}
            </p>
          </div>
        </div>
      </LoadingOverlay>
      // (
      //   <Modal
      //     show={this.state.modalShow}
      //     onHide={this.handleModalClose}
      //     backdrop="static"
      //     keyboard={false}
      //   >
      //     <Modal.Header closeButton>
      //       <Modal.Title>Modal title</Modal.Title>
      //     </Modal.Header>
      //     <Modal.Body>{this.state.modalData}</Modal.Body>
      //     <Modal.Footer>
      //       <Button variant="primary" onClick={this.handleModalClose}>
      //         Close
      //       </Button>
      //     </Modal.Footer>
      //   </Modal>
      // )
    );
  }
}
